var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("lotNumber")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.lot_number) + " ")]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v("Excavation Area")]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.extent) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
              attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
            },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("distanceOfRoad")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.distance_from_road) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v("Work Area Description")]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.excavationDetails.direction_of_road) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-col", {
            attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("typeOfWork")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.work_type) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("meansOfExcavation")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.means_of_excavation) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-col", {
            attrs: { cols: "12", lg: "6", xl: "6", md: "6", sm: "12" },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v("Directions to Work Area")]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.location) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("additionalComments")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [_vm._v(" " + _vm._s(_vm.excavationDetails.remarks) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("whiteLineLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.white_paint ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("blastingLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.excavationDetails.blasting ? "Yes" : "No") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-4 ml-1" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "caption grey--text text--darken-1" },
                [_vm._v(_vm._s(_vm.$t("railRoadHighwayLabel")))]
              ),
              _c(
                "v-row",
                {
                  staticClass:
                    "grey--text text--darken-4 font-weight-medium body-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.excavationDetails.rr_or_highway ? "Yes" : "No"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$store.state.Ticket.isEdit == "Digin"
        ? _c(
            "v-row",
            { staticClass: "mt-4 mb-2", attrs: { "no-gutters": "" } },
            [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                },
                [_vm._v(_vm._s(_vm.$t("DigInTypeOfLine")) + " ")]
              ),
            ]
          )
        : _vm._e(),
      _vm.$store.state.Ticket.isEdit &&
      _vm.$store.state.Ticket.isEdit === "Digin"
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("CATV")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_catv ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Electric")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_elec ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Fiber")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_fiber ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Gas")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_gas ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Blowing")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_blowing
                                  ? "Yes"
                                  : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Phone")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_phone ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Sewer")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_sewer ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.smAndDown ? "mt-4" : "",
                      attrs: {
                        cols: "12",
                        lg: "6",
                        xl: "6",
                        md: "6",
                        sm: "12",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Water")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_water ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Chemical Spill")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.excavationDetails.digin_spill ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("Unknown")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.excavationDetails.digin_other) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("membersToAdd")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.excavationDetails.membersToAdd) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.Ticket.isEdit &&
      _vm.$store.state.Ticket.isEdit === "UpdateTicket"
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("reasonForUpdate")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.excavationDetails.reason) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("remarksOrSpecificInfo")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.excavationDetails.remarks) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "my-4 ml-1" },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "caption grey--text text--darken-1" },
                        [_vm._v(_vm._s(_vm.$t("membersToAdd")))]
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass:
                            "grey--text text--darken-4 font-weight-medium body-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.excavationDetails.membersToAdd) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }