<template>
  <div>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("lotNumber")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.lot_number }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">Excavation Area</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.extent }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col
        cols="12"
        lg="6"
        xl="6"
        md="6"
        sm="12"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
      >
        <v-row class="caption grey--text text--darken-1">{{
          $t("distanceOfRoad")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.distance_from_road }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">Work Area Description</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.direction_of_road }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12"> </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("typeOfWork")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.work_type }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col cols="12" lg="6" xl="6" md="6" sm="12">
        <v-row class="caption grey--text text--darken-1">{{
          $t("meansOfExcavation")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.means_of_excavation }}
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" xl="6" md="6" sm="12"> </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">Directions to Work Area</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.location }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("additionalComments")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.remarks }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("whiteLineLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.white_paint ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("blastingLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.blasting ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row class="my-4 ml-1">
      <v-col>
        <v-row class="caption grey--text text--darken-1">{{
          $t("railRoadHighwayLabel")
        }}</v-row>
        <v-row class="grey--text text--darken-4 font-weight-medium body-2">
          {{ excavationDetails.rr_or_highway ? "Yes" : "No" }}
        </v-row>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mt-4 mb-2"
      v-if="$store.state.Ticket.isEdit == 'Digin'"
    >
      <span class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
        >{{ $t("DigInTypeOfLine") }}
      </span>
    </v-row>
    <div
      v-if="
        $store.state.Ticket.isEdit && $store.state.Ticket.isEdit === 'Digin'
      "
    >
      <v-row class="my-4 ml-1">
        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("CATV")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_catv ? "Yes" : "No" }}
          </v-row>
        </v-col>

        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Electric")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_elec ? "Yes" : "No" }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Fiber")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_fiber ? "Yes" : "No" }}
          </v-row>
        </v-col>

        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Gas")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_gas ? "Yes" : "No" }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Blowing")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_blowing ? "Yes" : "No" }}
          </v-row>
        </v-col>

        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Phone")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_phone ? "Yes" : "No" }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Sewer")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_sewer ? "Yes" : "No" }}
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          xl="6"
          md="6"
          sm="12"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''"
        >
          <v-row class="caption grey--text text--darken-1">{{
            $t("Water")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_water ? "Yes" : "No" }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col>
          <v-row class="caption grey--text text--darken-1">{{
            $t("Chemical Spill")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_spill ? "Yes" : "No" }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col>
          <v-row class="caption grey--text text--darken-1">{{
            $t("Unknown")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.digin_other }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col>
          <v-row class="caption grey--text text--darken-1">{{
            $t("membersToAdd")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.membersToAdd }}
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="
        $store.state.Ticket.isEdit &&
        $store.state.Ticket.isEdit === 'UpdateTicket'
      "
    >
      <v-row class="my-4 ml-1">
        <v-col>
          <v-row class="caption grey--text text--darken-1">{{
            $t("reasonForUpdate")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.reason }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col>
          <v-row class="caption grey--text text--darken-1">{{
            $t("remarksOrSpecificInfo")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.remarks }}
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-4 ml-1">
        <v-col>
          <v-row class="caption grey--text text--darken-1">{{
            $t("membersToAdd")
          }}</v-row>
          <v-row class="grey--text text--darken-4 font-weight-medium body-2">
            {{ excavationDetails.membersToAdd }}
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    excavationDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.moment = moment;
  },
};
</script>

<style lang="scss" scoped></style>
